import React from 'react'

function Univers() {
    return (
        <div className="Univers">
            La vie
        </div>
    );
}

export default Univers;
