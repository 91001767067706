import React from 'react'

function NotFound() {
    return (
        <div className="NotFound">
            404 Not Found
        </div>
    );
}

export default NotFound;
